var Cookiebar = (function() {
    'use strict'

    let button = document.querySelector('.cookiebutton')
    let cookieBar = document.querySelector('.cookiebar__container')

    init()

    function init () {
        var cache = localStorage.getItem('cookiebar')
        if (!cache) cookieBar.classList.add('show')
        button.addEventListener("click", handleClose)
    }

    function handleClose () {
        cookieBar.classList.remove('show')
        localStorage.setItem('cookiebar', true)
    }

    return {
        init: init
    }

}())
var Zoom = (function() {
    'use strict'

    init()

    function init () {
        $(document).on('click touchstart', 'div.image__container', function () {
            var targetPopupId = $(this).data('target')
            var targetPopup = $('#' + targetPopupId)
            targetPopup.removeClass('hide')
            keyClose(targetPopup)
        })

        $(document).on ("click", ".close-button-container, .image-overlay", function () {
            var targetPopupId = $(this).data('target')
            var targetPopup = $('#' + targetPopupId)
            closeButton(targetPopup)
        })
    }

    function closeButton(tag) {
        $(tag).addClass('hide')
    }

    function keyClose (tag) {
        document.body.addEventListener('keyup', e => {
            if (e.keyCode === 27) {
                tag.addClass('hide')
            }
        })
    }

    return {
        init: init
    }

}())

var Navigation = (function() {
    'use strict'

    let hamburgerButton = document.querySelector('.hamburger-container')
    let hamburgerIcon = document.querySelector('#hamburger')
    let overlay = document.querySelector('#mobile-navigation-overlay')
    let navigation = document.querySelector('.navbar__container')
    let contactbutton = document.querySelector('#contact-button')

    init()

    function init () {
        if (navigation) window.addEventListener('scroll', onscroll)
        hamburgerButton.addEventListener("click", handleNavigation)
        contactbutton.addEventListener("click", handleNavigation)
        
    }

    // CHANGE NAVIGATION ON SCROLL
    function onscroll () {
        if (window.pageYOffset >= 5) {
            navigation.classList.add('scroll')
        } else {
            navigation.classList.remove('scroll')
        }
    }

    // HANDLE MOBILE NAV
    function handleNavigation () {
        if (!overlay.classList.contains('active') && !hamburgerIcon.classList.contains('active')) {
            overlay.classList.add('active')
            hamburgerIcon.classList.add('active')
        } else {
            overlay.classList.remove('active')
            hamburgerIcon.classList.remove('active')
        }
    }

    return {
        init: init
    }

}())
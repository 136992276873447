var Jobs = (function() {
    'use strict'

    init()

    function init () {
        var items = document.querySelectorAll('.jobs__content-item')

        // if ($(items).hasClass('active')) {
        //     $(items).closest(items).find('jobs__content-item__body').show()
        // }

        // click button
        $(document).on('click touchstart', '.jobs__content-item__button', function () {
            console.log('testtest')
            if ($(this).parent().hasClass('active')) {
                $(this).parent().removeClass('active')
            } else {
                $(this).parent().addClass('active')
            }
        })

        // click header
        $(document).on('click touchstart', '.jobs__content-item__header', function (ev) {
            if ($(this).parent().hasClass('active')) {
                $(this).parent().removeClass('active')
            } else {
                $(this).parent().addClass('active')
            }
        })
    }

    return {
        init: init
    }
}())

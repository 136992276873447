/* eslint-disable no-unused-vars */
import styles from '../css/main.scss'
import jQuery from 'jquery'
import 'core-js/es/array/iterator'
import 'masonry-layout/masonry'

//
// ASSETS IMPORT
//
import supermachineLogo from '../img/supermachine.png'
import fisheyeLogo from '../img/logo_white.svg'
import fisheyeLogoRed from '../img/logo_red.svg'
import fisheyeSmallLogo from '../img/logo_small_white.svg'

import socialFacebook from '../img/social-fb.svg'
import socialInstagram from '../img/social-in.svg'
import socialLinkedin from '../img/social-ln.svg'
import socialYoutube from '../img/social-y.svg'
import socialTiktok from '../img/social-tt.svg' 


//
// COMPONENTS
//
import Navigation from './navigation.js'
import Jobs from './jobs.js'
// import VideoHeader from './homeheader.js'
import Cookiebar from './cookiebar.js'
import Zoom from './zoom.js'
import Event from './event.js'
import Masonry from 'masonry-layout/masonry'

// App main
const main = async () => {
    // Import our CSS
    // const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue')
    // // Create our vue instance
    // // eslint-disable-next-line new-cap
    if (document.querySelector('.vue-container')) {
        const vm = new Vue.default({
            el: '.vue-container',
            components: {
                // 'Navigation': () => import(/* webpackChunkName: "navigation" */ '../vue/navigation.vue'),
                'Loadmorecases': () => import(/* webpackChunkName: "loadmore" */ '../vue/loadmorecases.vue'),
                'Loadmorenews': () => import(/* webpackChunkName: "loadmore" */ '../vue/loadmorenews.vue')
            },
            data: {
            },
            methods: {
            },
            mounted () {
            }
        })
    }

}
// Execute async function
main().then((value) => {
    if (document.getElementById('supermachine-logo').src) document.getElementById('supermachine-logo').src = supermachineLogo
    if (document.getElementById('fisheye-logo-white')) document.getElementById('fisheye-logo-white').src = fisheyeLogo
    if (document.getElementById('fisheye-logo-color')) document.getElementById('fisheye-logo-color').src = fisheyeLogoRed

    if (document.getElementById('fisheye-logo-small')) document.getElementById('fisheye-logo-small').src = fisheyeSmallLogo

    if (document.getElementById('social-facebook')) document.getElementById('social-facebook').src = socialFacebook
    if (document.getElementById('social-instagram')) document.getElementById('social-instagram').src = socialInstagram
    if (document.getElementById('social-youtube')) document.getElementById('social-youtube').src = socialYoutube
    if (document.getElementById('social-linkedin')) document.getElementById('social-linkedin').src = socialLinkedin
    if (document.getElementById('social-tiktok')) document.getElementById('social-tiktok').src = socialTiktok

    let videoPlaying = 0;
    
    document.getElementById('video').onclick = function () {
        if(videoPlaying === 0){
            document.getElementById('video').play();
            document.getElementById('playVid').classList.add('hideButton');
            videoPlaying = 1;
        } else {
            document.getElementById('video').pause();
            document.getElementById('playVid').classList.remove('hideButton');
            videoPlaying = 0;
        }
    };
    document.getElementById('video').addEventListener('ended',myHandler,false);
    function myHandler(e){
        document.getElementById('playVid').classList.remove('hideButton');
        videoPlaying = 0;
    }
})

var Event = (function() {
    'use strict'

    init()

    function init () {
        $('input[type="radio"]').click(function(){
            var inputValue = $(this).attr("value");
            var targetBox = $(".friends");
            if (inputValue == "Yes") {
                targetBox.removeClass('hide')
            } else {
                targetBox.addClass('hide')
            }
        });
    }

    return {
        init: init
    }

}())